import { AuthTopNav } from '../../../components/TopNav/AuthTopNav'
import { AuthenticationForm } from '@dtx-company/inter-app/src/components/Authentication/AuthenticationForm'
import { Banner, Stack } from '@dtx-company/design-system/src'
import { FC, useMemo } from 'react'
import { GridLayout } from '../../../components/layout/GridLayout/GridLayout'
import { PrismicLinkSerializer } from '@app/prismic/src/components/PrismicLinkSerializer/PrismicLinkSerializer'
import { PrismicRichText } from '@prismicio/react'
import { SignInPageProps } from './SigninPageContainer.types'

export const SigninPageContainer: FC<SignInPageProps> = ({
  fromFlowpage,
  prismicData,
  inviteState,
  planTypeQueryParams
}) => {
  const prismicInfoBannerSlice = useMemo(() => {
    return prismicData?.body.find(slice => slice.slice_type === 'info_banner')
  }, [prismicData])

  return (
    <GridLayout
      headerSlot={{
        children: <AuthTopNav color="primary.main" isSignin />
      }}
      primarySlot={{
        backgroundImgSrc: prismicData?.background?.url || undefined,
        children: (
          <Stack
            spacing={4}
            sx={theme => ({ maxWidth: theme.spacing(115) })}
            data-testid="signin-page-container"
          >
            <AuthenticationForm
              action="sign in"
              product={fromFlowpage ? 'flowpage' : 'flowcode'}
              inviteState={inviteState}
              planTypeQueryParams={planTypeQueryParams}
            />
            {prismicInfoBannerSlice?.primary.active && (
              <Banner
                title={
                  <PrismicRichText
                    field={prismicInfoBannerSlice.primary.title}
                    components={{
                      heading3: ({ children }) => <>{children}</> // We force an h3 in prismic: https://flowcode-ui.prismic.io/masks/signin_page.json/
                    }}
                  />
                }
                description={
                  <PrismicRichText
                    field={prismicInfoBannerSlice.primary.description}
                    components={{
                      paragraph: ({ children }) => <>{children}</>,
                      hyperlink: ({ children, node }) => (
                        <PrismicLinkSerializer node={node} heapId="SignInPageInfoBanner">
                          {children}
                        </PrismicLinkSerializer>
                      )
                    }}
                  />
                }
              />
            )}
          </Stack>
        ),
        graphicPosition: 'background',
        verticalAlignment: prismicData.primary_vertical_alignment
      }}
    />
  )
}
